export default [
  {
    path: '/application/select-child/:id',
    name: 'application-select-child',
    component: () => import('@/views/Application/ApplicationSelectChild.vue'),
  },
  {
    path: '/application/:id',
    name: 'application-create',
    component: () => import('@/views/Application/ApplicationSteps.vue'),
  },
  {
    path: '/application-submission/:id',
    name: 'my-applications-submission',
    component: () => import('@/views/My/ApplicationSubmission'),
  }
]
