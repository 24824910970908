import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    authSettings: {
      show_login_form: false,
      show_registration_form: false,
    },
  },
  getters: {
    getAuthSettings: state => state.authSettings,
  },
  mutations: {
    SET_AUTH_SETTINGS(state, val) {
      state.authSettings = val
    },
  },
  actions: {
    async fetchAuthSettings({ commit }) {
      try {
        const response = await axios.get('auth-settings')
        commit('SET_AUTH_SETTINGS', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
  },
}