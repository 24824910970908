const hasValue = (condition, value) => {
  let hasValue = false
  switch (condition.operator) {
    case '==':
      if (condition.value == value || (Array.isArray(value) && value.length === 1 && value.includes(condition.value))) {
        hasValue = true
      }
      break;
    case '!=':
      if (condition.value !== value || (Array.isArray(value) && value.length === 1 && !value.includes(condition.value))) {
        hasValue = true
      }
      break;
    case '==contains':
      if (Array.isArray(condition.value)) {
        if (Array.isArray(value)) {
          const found = value.some(r => condition.value.includes(String(r)))
          if (found) {
            hasValue = true
          }
        } else {
          if (condition.value.includes(String(value))) {
            hasValue = true
          }
        }
      } else {
        if (value.includes(condition.value)) {
          hasValue = true
        }
      }
      break;
    case '!=contains':
      if (value && Array.isArray(value) && !value.includes(condition.value)) {
        hasValue = true
      }
      break;
    case '==empty':
      if (!value) {
        hasValue = true
      }
      break;
    case '!=empty':
      if (value) {
        hasValue = true
      }
      break;
    case '<':
      if (+condition.value < +value) {
        hasValue = true
      }
      break;
    case '>':
      if (+condition.value > +value) {
        hasValue = true
      }
      break;
  }

  return hasValue
}
const designateValue = (type, def, options = []) => {
  if (type === 'group_selection' && def !== 'not_accepted') {
    return def
  }

  if (def && def !== 'not_accepted') {
    if (type === 'checkbox' || type === 'multi_select') return def
    if (type === 'file_upload') return []

    if(type === 'dropdown' && options.length) {
      const availableValues = options.map(option => option.value)
      if (availableValues.includes(def)) {
        return def
      }
      return null
    }

    return def
  }

  if (type === 'checkbox' || type === 'multi_select') return []
  if (type === 'dropdown') return null
  if (type === 'file_upload') return []

  return null
}
const designateOptions = (type, options) => {
  if (type === 'dropdown') {
    return options.reduce((arr, item) => {
      arr.push({ value: item, text: item })
      return arr
    }, [{ value: null, text: 'Select option' }])
  }

  return options
}
const transformField = type => {
  switch (type) {
    case 'short_text':
      return 'text'
    case 'phone':
      return 'tel'
    default:
      return type
  }
}
const checkHiddenAttr = obj => {
  return obj.length > 0
}

const checkIfStepCanBeHidden = (step, fromStep = null) => {
  return !step.status || ['Approved', 'Draft'].includes(step.status) || ['Rejected', 'Draft'].includes(fromStep?.status)
}

export {
  hasValue,
  designateValue,
  designateOptions,
  transformField,
  checkHiddenAttr,
  checkIfStepCanBeHidden,
}
