import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

import pages from '@/router/pages'
import parent from '@/router/parent'

Vue.use(VueRouter)

const userData = getUserData()

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior () {
		return { x: 0, y: 0 }
	},
	routes:	[
		{ path: '/', redirect: getHomeRouteForLoggedInUser(userData?.role) },
		...pages,
		...parent,
		{
			path: '*',
			redirect: 'error-404',
		},
	],
})

router.beforeEach((to, _, next) => {
	const isLoggedIn = isUserLoggedIn()
	if (!isLoggedIn && to.name == 'home') {
		return next({name: 'auth-login'})
	}
	if (!canNavigate(to)) {
		if (!isLoggedIn) {
			return next({name: 'auth-login'})
		}
		return next({ name: 'home' })
	}
	// Redirect if logged in
	if (to.meta.redirectIfLoggedIn && isLoggedIn) {
		// return next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
	}
	return next()
})

export default router
