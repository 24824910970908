export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'main',
      resource: 'Auth',
      action: 'read',
    },
  },
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import('@/views/Home/Home.vue'),
  //   meta: {
  //     resource: 'Auth',
  //     redirectIfLoggedIn: true,
  //   }
  // },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      resource: 'Auth',
      redirectIfLoggedIn: true,
    }
  },
  {
    path: '/login-as-user',
    name: 'auth-login-as-user',
    component: () => import('@/views/LoginAsUser.vue'),
    meta: {
      resource: 'Auth',
      redirectIfLoggedIn: true,
    }
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import('@/views/Register.vue'),
    meta: {
      resource: 'Auth',
      redirectIfLoggedIn: true,
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      resource: 'Auth',
      redirectIfLoggedIn: true,
    }
  },
  {
    path: '/reset-password/:token/:email',
    name: 'reset-password',
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      resource: 'Auth',
      redirectIfLoggedIn: true,
    }
  },
  {
    path: '/registration-staff',
    name: 'registration-staff',
    component: () => import('@/views/Register.vue'),
    meta: {
      resource: 'Auth',
      redirectIfLoggedIn: true,
      role: 'staff'
    }
  },
  {
    path: '/programs',
    name: 'programs-list',
    component: () => import('@/views/Program/UpcomingPrograms.vue'),
    meta: {
      resource: 'Auth',
      redirectIfLoggedIn: true,
    }
  },
  // {
  //   path: '/programs-details/:id',
  //   name: 'programs-details',
  //   component: () => import('@/views/Program/ProgramDetails.vue'),
  //   meta: {
  //     resource: 'Auth',
  //     redirectIfLoggedIn: true,
  //   }
  // },
  {
    path: '/information',
    name: 'information',
    component: () => import('@/views/Static/Information.vue'),
    meta: {
      resource: 'Auth',
      redirectIfLoggedIn: true,
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/Static/About.vue'),
    meta: {
      resource: 'Auth',
      redirectIfLoggedIn: true,
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/My/Applications.vue'),
    meta: {
      resource: 'Auth',
      redirectIfLoggedIn: true,
    }
  },
  {
    path: '/my-tasks',
    name: 'tasks',
    component: () => import('@/views/Tasks/Tasks.vue'),
    meta: {
      resource: 'Auth',
      redirectIfLoggedIn: true,
    }
  },
  {
    path: '/application-task/:id/:application_id',
    name: 'application-task-create',
    component: () => import('@/views/Tasks/ApplicationTaskAdd.vue'),
    meta: {
      resource: 'Auth',
      redirectIfLoggedIn: true,
    }
  },
  {
    path: '/blog/:slug',
    name: 'post-details',
    component: () => import('@/views/Post/Post.vue'),
  },
  {
    path: '/my-family-list',
    name: 'family-list',
    component: () => import('@/views/Family/FamilyList.vue'),
    meta: {
      resource: 'Auth',
      redirectIfLoggedIn: true,
    }
  },
  // {
  //   path: '/add-child',
  //   name: 'add-child',
  //   component: () => import('@/views/Children/AddChild.vue'),
  // },
  {
    path: '/payment-portal',
    name: 'payment-portal',
    component: () => import('@/views/PaymentPortal/PaymentPortal.vue'),
    meta: {
      resource: 'Auth',
      redirectIfLoggedIn: true,
    }
  },
]
