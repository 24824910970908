import axios from '@/libs/axios'
// import moment from 'moment'
// Notification
// import { useToast } from 'vue-toastification/composition'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// // Use toast
// const toast = useToast()

export default {
  namespaced: true,
  state: {
    transactionsTableItems: {},
    isTransactionsTableBusy: false,
    paymentContractsUpcomingTransactions: [],
    paymentPlanUpcomingTransactionsTables: [],
    isUpcomingPaymentsBusy: false,
    selectedUpcomingPayment: null,
    creditCardsList: [],
    selectedCreditCard: '',
    cardForRemove: null,
    isModalLoaidng: false,
    applications: [],
    selectedApplication: null,
    chargesList: [],
    paymentAmount: 0,
    paymentMethod: 'Credit Card',
    paymentMethodsList: ['Credit Card', 'Cash Bank'],
    cashBankBalance: 0,
    includeCashBank: false,
    statusModalTitle: '',
    statusModalStatus: '',
    statusModalHeader: '',
    statusModalMessage: '',
    familyId: null,
    amountOfUpcomingPayments: null,
  },
  getters: {
    getTransactionsTableItems: state => state.transactionsTableItems,
    getIsTransactionsTableBusy: state => state.isTransactionsTableBusy,
    getPaymentContractsUpcomingTransactions: state => state.paymentContractsUpcomingTransactions,
    getPaymentPlanUpcomingTransactionsTables: state => state.paymentPlanUpcomingTransactionsTables,
    getIsUpcomingPaymentsBusy: state => state.isUpcomingPaymentsBusy,
    getSelectedUpcomingPayment: state => state.selectedUpcomingPayment,
    getCreditCardsList: state => state.creditCardsList,
    getSelectedCreditCard: state => state.selectedCreditCard,
    getCardForRemove: state => state.cardForRemove,
    getIsModalLoaidng: state => state.isModalLoaidng,
    getSelectedCardInfo: state => {
      return state.creditCardsList.find(card => card.id == state.selectedCreditCard);
    },
    getApplications: state => state.applications,
    getSelectedApplication: state => state.selectedApplication,
    getChargesList: state => state.chargesList,
    getPaymentAmount: state => state.paymentAmount,
    getPaymentMethod: state => state.paymentMethod,
    getPaymentMethodsList: state => state.paymentMethodsList,
    getCashBankBalance: state => state.cashBankBalance,
    getIncludeCashBank: state => state.includeCashBank,
    getStatusModalTitle: state => state.statusModalTitle,
    getStatusModalStatus: state => state.statusModalStatus,
    getStatusModalHeader: state => state.statusModalHeader,
    getStatusModalMessage: state => state.statusModalMessage,
    getFamilyId: state => state.familyId,
    getAmountOfUpcomingPayments: state => state.amountOfUpcomingPayments,
  },
  mutations: {
    SET_TRANSACTIONS_TABLE_ITEMS: (state, val) => {
      state.transactionsTableItems = val
    },
    SET_TRANSACTIONS_TABLE_BUSY_STATE: (state, val) => {
      state.isTransactionsTableBusy = val
    },
    SET_PAYMENT_CONTRACTS_UPCOMING_TRANSACTIONS: (state, val) => {
      state.paymentContractsUpcomingTransactions = val
    },
    SET_PAYMENT_PLAN_UPCOMING_TRANSACTIONS_TABLES: (state, val) => {
      state.paymentPlanUpcomingTransactionsTables = val
    },
    SET_UPCOMING_PAYMENTS_BUSY_STATE: (state, val) => {
      state.isUpcomingPaymentsBusy = val
    },
    SET_SELECTED_UPCOMING_PAYMENT: (state, val) => {
      state.selectedUpcomingPayment = val
    },
    SET_CREDIT_CARDS_LIST: (state, val) => {
      state.creditCardsList = val
    },
    SET_SELECTED_CREDIT_CARD: (state, val) => {
      state.selectedCreditCard = val
    },
    SET_CARD_FOR_REMOVE: (state, val) => {
      state.cardForRemove = val
    },
    SET_MODAL_LOADING_STATE: (state, val) => {
      state.isModalLoaidng = val
    },
    SET_APPLICATIONS: (state, val) => {
      state.applications = val
    },
    SET_SLECTED_APPLICATION: (state, val) => {
      state.selectedApplication = val
    },
    SET_CHARGES_LIST: (state, val) => {
      state.chargesList = val
    },
    SET_PAYMENT_AMOUNT: (state, val) => {
      state.paymentAmount = val
    },
    SET_PAYMENT_METHOD: (state, val) => {
      state.paymentMethod = val
    },
    SET_CASH_BANK_BALANCE: (state, val) => {
      state.cashBankBalance = val
    },
    SET_INCLUDE_CASH_BANK_STATUS: (state, val) => {
      state.includeCashBank = val
    },
    SET_STATUS_MODAL_TITLE: (state, val) => {
      state.statusModalTitle = val
    },
    SET_STATUS_MODAL_STATUS: (state, val) => {
      state.statusModalStatus = val
    },
    SET_STATUS_MODAL_HEADER: (state, val) => {
      state.statusModalHeader = val
    },
    SET_STATUS_MODAL_MESSAGE: (state, val) => {
      state.statusModalMessage = val
    },
    SET_FAMILY_ID: (state, val) => {
      state.familyId = val
    },
    SET_AMOUNT_OF_UPCOMING_PAYMENTS: (state, val) => {
      state.amountOfUpcomingPayments = val
    },
  },
  actions: {
    async fetchTransactionsList(store) {
      store.commit('SET_TRANSACTIONS_TABLE_BUSY_STATE', true)
			try {
        const queryParams = {
          sortBy: 'payment_date',
          sortDesc: true,
        }
				const response = await axios.get('/transactions', { params: queryParams })
        const transactionsPerApplications = {}

        response.data.data.forEach(item => {
          if (item.application_id) {
            if (transactionsPerApplications[item.application_id]) {
              transactionsPerApplications[item.application_id].push(item)
            } else {
              transactionsPerApplications[item.application_id] = [item]
            }
          }
        })
        store.commit('SET_TRANSACTIONS_TABLE_ITEMS', transactionsPerApplications)
			} catch (error) {
				return error
			} finally {
        store.commit('SET_TRANSACTIONS_TABLE_BUSY_STATE', false)
      }
		},
    async fetchUpcomingPayments(store) {
      store.commit('SET_UPCOMING_PAYMENTS_BUSY_STATE', true)
      try {
        const queryParams = {
          status: ['Pending', 'Payment Failed'],
        }
        const response = await axios.get('/pay-condition-transactions', { params: queryParams })
        
        const paymentContractsUpcomingPayments = [];
        const paymentPlanUpcomingPaymentsTables = {};

        for(let item of response.data.data) {
          if (item.condition_type == "PaymentOption") {
            paymentContractsUpcomingPayments.push(item)
            continue
          }
          if (paymentPlanUpcomingPaymentsTables[item.app_pay_cond_id]) {
            paymentPlanUpcomingPaymentsTables[item.app_pay_cond_id].push(item)
          } else {
            paymentPlanUpcomingPaymentsTables[item.app_pay_cond_id] = [item]
          }
        }
        store.commit('SET_PAYMENT_CONTRACTS_UPCOMING_TRANSACTIONS', paymentContractsUpcomingPayments)
        store.commit('SET_PAYMENT_PLAN_UPCOMING_TRANSACTIONS_TABLES', paymentPlanUpcomingPaymentsTables)

      } catch (error) {
        return error
      } finally {
        store.commit('SET_UPCOMING_PAYMENTS_BUSY_STATE', false)
      }
    },
    async fetchCreditCardsList(store) {
			try {
				const response = await axios.get('/cards')
        store.commit('SET_CREDIT_CARDS_LIST', response.data.data)
			} catch (error) {
				return error
			}
		},
    async createNewCreditCard(store, queryParams) {
			try {
				const response = await axios.post('/cards', queryParams)
        store.commit('SET_SELECTED_CREDIT_CARD', response.data.data.id)
			} catch (error) {
				return error
			}
		},
    async deleteCreditCard(store, id) {
			try {
				await axios.delete(`/cards/${id}`)
			} catch (error) {
				return error
			}
		},
    async replaceCreditCard(store, payload) {
      try {
        await axios.post(`/cards/${payload.id}/replace`, payload.params)
      } catch (error) {
        return error
      }
    },
    async payUpcomingPaymentTransaction(store, payload) {
      try {
        await axios.post(`/pay-condition-transactions/${payload.id}/pay`, payload.params)
      } catch (error) {
				throw new Error(error)
			}
    },
    async fetchApplications(store, queryParams) {
			try {
				const response = await axios.get('/applications', { params: queryParams })
        store.commit('SET_APPLICATIONS', response.data.data)
			} catch (error) {
				return error
			}
		},
    async fetchCharges(store, queryParams) {
			try {
				const response = await axios.get('/transactions', { params: queryParams })
        const filteredCherges = response.data.data.filter(charge => charge.unpaid_sum !== 0 && charge.status === 'Completed')
        store.commit('SET_CHARGES_LIST', filteredCherges)
			} catch (error) {
				return error
			}
		},
    async sendNewTransaction(store, queryParams) {
      try {
        await axios.post(`/transactions`, queryParams)
      } catch (error) {
				throw new Error(error)
			}
    },
    async fetchCashBankTransactions(store, queryParams) {
      try {
        return await axios.get('/transactions', { params: queryParams })
      } catch (error) {
				return error
			}
    },
    async fetchCashBankBalance(store) {
      try {
        const response = await axios.post(`/family/${store.rootState.family.familyId}/balance`)
        store.commit('SET_CASH_BANK_BALANCE', response.data.data.amount)
      } catch (error) {
				return error
			}
    },
    async fetchWallets(store, queryParams) {
			try {
				return await axios.get('/wallets', { params: queryParams })
			} catch (error) {
				return error
			}
		},
    async changeDefaultCard(store, queryParams) {
      try {
        await axios.post(`/cards/${queryParams.card_id}/change-default`, queryParams)
      } catch (error) {
				throw new Error(error)
			}
    },
  },
}