import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    infoMessages: [],
  },
  getters: {
    getInfoMessages: state => state.infoMessages,
  },
  mutations: {
    SET_INFO_MESSAGES: (state, val) => {
      state.infoMessages = val
    },
    REMOVE_INFO_MESSAGE: (state, index) => {
      state.infoMessages.splice(index, 1)
    },
  },
  actions: {
    async fetchInfoMessages({commit}, payload) {
			try {
				const response = await axios.get('/applications/info-messages', { params: payload })
        commit('SET_INFO_MESSAGES', response.data.data)
			} catch (error) {
				return error
			}
		},
  },
}