import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

import router from './router'
import store      from './store'
import Vuelidate  from 'vuelidate';
import VueTheMask from 'vue-the-mask';
import VueCardFormat from 'vue-credit-card-validation';

import VueCookies from 'vue-cookies'
import App from './App.vue'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(Vuelidate)
Vue.use(VueTheMask)
Vue.use(VueCardFormat);
// Composition API
Vue.use(VueCompositionAPI)

Vue.use(VueCookies, { expire: '7d' })

Vue.config.productionTip = false

if (['production', 'stage'].includes(process.env.VUE_APP_ENVIRONMENT)) {
	Sentry.init({
		Vue,
		environment: process.env.VUE_APP_ENVIRONMENT,
		release: `${process.env.VUE_APP_NAME}`,
		dsn: process.env.VUE_APP_SENTRY_DSN,
		integrations: [
			new BrowserTracing({
				tracingOrigins: [process.env.VUE_APP_ROOT_API, /^\//],
			}),
		],
		tracesSampleRate: 1.0,
		tracingOptions: {
			trackComponents: true,
		},
    logErrors: true,
	})
}

import(/* webpackChunkName: "config" */ '../site-config.json')
	.then(r => {
		Vue.prototype.$config = r.default
	})
	.finally(() => {
		new Vue({
			router,
			store,
			render: h => h(App)
		}).$mount('#app')
	});

