<template>
  <div class="site__header">

    <div
        v-if="isUserLoggedAs"
        class="site__header-as-user"
    >
        <span @click="adminLogout">
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
          <path d="M13.4577 8.5H3.54102" stroke="#7D1E36" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8.49935 13.4584L3.54102 8.50002L8.49935 3.54169" stroke="#7D1E36" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <u>Go Back</u>
        </span>
      You are logged as {{userData.full_name}}
    </div>

    <div class="site__centered">

      <router-link
          :to="{name: 'home'}"
          class="logo"
      >
        <img
            :src="$config['logo']"
            :alt="$config['name']"
            style="max-height: 70px;"
        />
        <span class="mobile-hidden" 
          v-if="isUserLoggedIn && userData"
          style="marginLeft: 2rem"
        >Hello, {{ userData.fullName }}</span>
      </router-link>

      <div class="site__header-right">

        <navigation
            @logout="logout"
            @toggleMenu="toggleMenu"
            :value="isOpenMobileMenu"/>

        <router-link
            v-if="!isUserLoggedIn"
            :to="{name:'auth-login'}"
            :class="!authSettings.show_registration_form ? 'btn btn--color-dark': '' + 'site__header-link mobile-hidden '"
        >Login</router-link>

        <a
            v-if="isUserLoggedIn"
            class="mobile-hidden"
            @click="logout">
          Logout
        </a>

        <router-link
            v-if="!isUserLoggedIn && authSettings.show_registration_form"
            :to="{name: 'registration'}"
            class="btn btn--color-dark mobile-hidden"><span>Register</span>
        </router-link>

        <router-link
            v-if="isUserLoggedIn"
            :to="{name: 'home'}"
            class="btn btn--color-dark menu-button"><span>My Account</span>
        </router-link>

        <div class="menu-burger desktop-hidden" @click="toggleMenu">
          <span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useJwt from "@/auth/jwt/useJwt";
import { initialAbility}  from "@/libs/acl/config";
import { isUserLoggedIn, getUserData } from '@/auth/utils'

import Navigation from "@/components/site/Navigation";

export default {
  name: "SiteHeader",
  components: {Navigation},
  data() {
    return {
      isOpenMobileMenu: false,
    }
  },
  computed: {
    isUserLoggedIn () {
      return this.$store.getters.getUserStatus || isUserLoggedIn()
    },
    isUserLoggedAs () {
      return this.$store.getters.getASUserStatus || sessionStorage.getItem('loggedAsUser')
    },
    userData () {
      return getUserData()
    },
    authSettings () {
      return this.$store.getters["authSettings/getAuthSettings"]
    },
  },
  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem('userData')

      this.$store.commit('SET_USER_DATA', null)
      this.$store.commit('CHANGE_USER_STATUS', false)
      this.$store.commit('CHANGE_ADMIN_USER_STATUS', false)
      this.$store.commit('family/SET_FAMILY_ID', null)
      
      sessionStorage.removeItem('loggedAsUser')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    adminLogout() {        
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem('userData')
      sessionStorage.removeItem('loggedAsUser')
      
      this.$store.commit('SET_USER_DATA', null)
      this.$store.commit('CHANGE_USER_STATUS', false)
      this.$store.commit('CHANGE_ADMIN_USER_STATUS', false)

      // Reset ability
      this.$ability.update(initialAbility)

      window.location.href = sessionStorage.getItem('cameFromURL')
    },
    toggleMenu () {
      this.isOpenMobileMenu = !this.isOpenMobileMenu
    },
  }
}
</script>
