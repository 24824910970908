import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    uploadFiles(ctx, {name, file}) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/file/upload/${name}`, file, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
  },
}