import Vue from 'vue'
import Vuex from 'vuex'
import files from './modules/files'
import applications from './modules/applications'
import programs from './modules/programs'
import applicationRegistration from '@/store/modules/applicationRegistration'
import paymentPortal from '@/store/modules/paymentPortal'
import family from '@/store/modules/family'
import alertMessage from '@/store/modules/alertMessage'
import authSettings from '@/store/modules/authSettings'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    files,
    applications,
    programs,
    applicationRegistration,
    paymentPortal,
    family,
    alertMessage,
    authSettings,
  },
  state: {
    isAuthorized: false,
    loggedAsUser: false,
    loading: false,
    userData: JSON.parse(localStorage.getItem('userData')) || null,
  },
  mutations: {
    CHANGE_USER_STATUS(state, val) {
      state.isAuthorized = val
    },
    CHANGE_ADMIN_USER_STATUS(state, val) {
      state.loggedAsUser = val
    },
    SET_LOADING_STATUS(state, val) {
      state.loading = val
    },
    SET_USER_DATA(state, val) {
      state.userData = val
    },
  },
  actions: {
    setUserStatus(commit, val) {
      commit('CHANGE_USER_STATUS', val)
    },
    setAsUserStatus(commit, val) {
      commit('CHANGE_ADMIN_USER_STATUS', val)
    },
    setLoadingStatus(commit, val) {
      commit('CHANGE_ADMIN_USER_STATUS', val)
    }
  },
  getters: {
    getASUserStatus: state => state.loggedAsUser,
    getUserStatus: state => state.isAuthorized,
    getLoadingStatus: state => state.loading,
    getUserData: state => state.userData,
  }
})
