import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    familyId: null,
    familyList: [],
  },
  getters: {
    getFamilyId: state => state.familyId,
    getFamilyList: state => state.familyList,
  },
  mutations: {
    SET_FAMILY_ID: (state, val) => {
      state.familyId = val
    },
    SET_FAMILY_LIST: (state, val) => {
      state.familyList = val
    },
  },
  actions: {
    async addNewMember(store, queryParams) {
			try {
				await axios.post('/family/member/add', queryParams)
			} catch (error) {
				return error
			}
		},
    async fetchFamilyList(store) {
			try {
				const response = await axios.get('/user-family/')
        store.commit('SET_FAMILY_LIST', response.data.data)
			} catch (error) {
				return error
			}
		},
  },
}