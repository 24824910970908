<template>
    <div class="site__footer">

        <div class="site__footer-top">
            <div class="site__centered footer footer-mobile">

                <div class="site__footer-left">
                    <router-link
                        :to="{name: 'home'}"
                    >
                        <img
                            :src="$config['logo_footer']"
                            :alt="$config['name']"
                            style="height:72px;"
                        />
                    </router-link>
                </div>

                <div class="footer-menu">
                    <div
                        v-if="showQuickLinks"
                        class="footer-menu__column mobile-hidden"
                    >
                        <h3 class="footer-menu__topic">Quick links</h3>
                        <p><router-link to="/">About</router-link></p>
                        <p><router-link to="/">Information</router-link></p>
                        <p><router-link :to="{name: 'programs-list'}">Register Here</router-link></p>
                        <p><router-link :to="{name: 'auth-login'}">Parents Login</router-link></p>
                        <p><router-link :to="{name: 'registration'}">Parents Register</router-link></p>
                    </div>

                    <div class="footer-menu__column">
                        <h3 class="footer-menu__topic">Contact</h3>
                        <p>
                            <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.75 12.315V14.967C15.7501 15.1569 15.6781 15.3397 15.5487 15.4786C15.4192 15.6176 15.2419 15.7022 15.0525 15.7155C14.7247 15.738 14.457 15.75 14.25 15.75C7.62225 15.75 2.25 10.3778 2.25 3.75C2.25 3.543 2.26125 3.27525 2.2845 2.9475C2.29779 2.75808 2.38244 2.58076 2.52135 2.45131C2.66027 2.32186 2.84312 2.24991 3.033 2.25H5.685C5.77803 2.24991 5.86777 2.28439 5.9368 2.34677C6.00582 2.40914 6.0492 2.49494 6.0585 2.5875C6.07575 2.76 6.0915 2.89725 6.1065 3.0015C6.25555 4.04169 6.561 5.05337 7.0125 6.00225C7.08375 6.15225 7.03725 6.3315 6.90225 6.4275L5.28375 7.584C6.27334 9.88984 8.11091 11.7274 10.4167 12.717L11.5717 11.1015C11.619 11.0355 11.6878 10.9882 11.7664 10.9677C11.8449 10.9473 11.9281 10.9551 12.0015 10.9897C12.9503 11.4404 13.9617 11.7451 15.0015 11.8935C15.1058 11.9085 15.243 11.925 15.414 11.9415C15.5064 11.951 15.592 11.9944 15.6543 12.0634C15.7165 12.1324 15.7509 12.2221 15.7507 12.315H15.75Z" fill="#E8E6E6"/>
                            </svg>
                            <router-link to="/">{{ $config['contact']['phone'] }}</router-link></p>
                        <p>
                            <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.68225 5.14049L8.6175 0.982493C8.73406 0.912554 8.86744 0.87561 9.00338 0.87561C9.13931 0.87561 9.27269 0.912554 9.38925 0.982493L16.3177 5.14124C16.3733 5.17455 16.4194 5.2217 16.4513 5.27809C16.4832 5.33448 16.5 5.39819 16.5 5.46299V15C16.5 15.1989 16.421 15.3897 16.2803 15.5303C16.1397 15.671 15.9489 15.75 15.75 15.75H2.25C2.05109 15.75 1.86032 15.671 1.71967 15.5303C1.57902 15.3897 1.5 15.1989 1.5 15V5.46224C1.49999 5.39744 1.51677 5.33373 1.54871 5.27734C1.58065 5.22095 1.62666 5.1738 1.68225 5.14049ZM13.7595 6.18299L9.04575 10.2622L4.23525 6.17849L3.26475 7.32149L9.05475 12.2377L14.7405 7.31774L13.7595 6.18299Z" fill="#E8E6E6"/>
                            </svg>
                            <router-link to="/">{{ $config['contact']['email'] }}</router-link></p>
                        <p>
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 264.018 264.018" style="enable-background:new 0 0 264.018 264.018;" xml:space="preserve"><g><path fill="#E8E6E6" d="M132.009,0c-42.66,0-77.366,34.706-77.366,77.366c0,11.634,2.52,22.815,7.488,33.24c0.1,0.223,0.205,0.442,0.317,0.661
                            l58.454,113.179c2.146,4.154,6.431,6.764,11.106,6.764c4.676,0,8.961-2.609,11.106-6.764l58.438-113.148
                            c0.101-0.195,0.195-0.392,0.285-0.591c5.001-10.455,7.536-21.67,7.536-33.341C209.375,34.706,174.669,0,132.009,0z
                             M132.009,117.861c-22.329,0-40.495-18.166-40.495-40.495c0-22.328,18.166-40.494,40.495-40.494s40.495,18.166,40.495,40.494
                            C172.504,99.695,154.338,117.861,132.009,117.861z"/><path fill="#E8E6E6" d="M161.81,249.018h-59.602c-4.143,0-7.5,3.357-7.5,7.5c0,4.143,3.357,7.5,7.5,7.5h59.602c4.143,0,7.5-3.357,7.5-7.5
                            C169.31,252.375,165.952,249.018,161.81,249.018z"/></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>

                            <router-link to="/">{{ $config['contact']['address'] }}</router-link></p>

                        <div class="social">
                            <a :href="$config['social']['facebook']" target="_blank" rel="nofollow" class="social__item">
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14 13.5H16.5L17.5 9.5H14V7.5C14 6.47 14 5.5 16 5.5H17.5V2.14C17.174 2.097 15.943 2 14.643 2C11.928 2 10 3.657 10 6.7V9.5H7V13.5H10V22H14V13.5Z" fill="white"/>
                                </svg>
                            </a>
                            <a :href="$config['social']['instagram']" target="_blank" rel="nofollow" class="social__item">
                                <svg class="instagram-logo" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="100" height="100"
                                 viewBox="0 0 551.034 551.034" style="enable-background:new 0 0 551.034 551.034;" xml:space="preserve">
                                    <path fill="#E8E6E6" class="logo" id="XMLID_17_" d="M386.878,0H164.156C73.64,0,0,73.64,0,164.156v222.722 c0,90.516,73.64,164.156,164.156,164.156h222.722c90.516,0,164.156-73.64,164.156-164.156V164.156 C551.033,73.64,477.393,0,386.878,0z M495.6,386.878c0,60.045-48.677,108.722-108.722,108.722H164.156 c-60.045,0-108.722-48.677-108.722-108.722V164.156c0-60.046,48.677-108.722,108.722-108.722h222.722 c60.045,0,108.722,48.676,108.722,108.722L495.6,386.878L495.6,386.878z"/>
                                
                                    <path id="XMLID_81_" fill="#E8E6E6" d="M275.517,133C196.933,133,133,196.933,133,275.516 s63.933,142.517,142.517,142.517S418.034,354.1,418.034,275.516S354.101,133,275.517,133z M275.517,362.6 c-48.095,0-87.083-38.988-87.083-87.083s38.989-87.083,87.083-87.083c48.095,0,87.083,38.988,87.083,87.083 C362.6,323.611,323.611,362.6,275.517,362.6z"/>
                                
                                    <circle id="XMLID_83_" fill="#E8E6E6" cx="418.306" cy="134.072" r="34.149"/>
                                </svg>
                            </a>
                            <a :href="$config['social']['youtube']" target="_blank" rel="nofollow" class="social__item">
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.543 6.498C22 8.28 22 12 22 12C22 12 22 15.72 21.543 17.502C21.289 18.487 20.546 19.262 19.605 19.524C17.896 20 12 20 12 20C12 20 6.107 20 4.395 19.524C3.45 19.258 2.708 18.484 2.457 17.502C2 15.72 2 12 2 12C2 12 2 8.28 2.457 6.498C2.711 5.513 3.454 4.738 4.395 4.476C6.107 4 12 4 12 4C12 4 17.896 4 19.605 4.476C20.55 4.742 21.292 5.516 21.543 6.498ZM10 15.5L16 12L10 8.5V15.5Z" fill="white"/>
                                </svg>
                            </a>
                        </div>

                    </div>
                </div>
            </div>

        </div>

        <div class="site__footer-bottom">
            <div class="site__centered">
              {{ $config['footer']['copyright'] }}
		Powered by
        <a href="https://www.boldersolutions.io/" target="_blank" rel="nofollow"
          >Bolder Solutions</a
        >
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: "SiteFooter",
    computed: {
        showQuickLinks() {
            return this.$config['show_quick_links']
        }
    }
}
</script>
