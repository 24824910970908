<template>
	<div>
		<transition name="fade">
		<div v-if="loading" class="loading">
			<div>
				<LoaderSVG />
			</div>
		</div>
		</transition>
	</div>
</template>

<script>

import LoaderSVG from '@/assets/svg/data-loader.svg'
export default {
	name: "Loader",
	components: {
		LoaderSVG,
	},
	data() {
		return {
			loading: false,
		}
	},
}
</script>
<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to  {
  opacity: 0;
}
.loading{
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffffc2;
  z-index: 10000;
}
</style>