import axios from '@axios'

export default {
  namespaced: true,
  state: {
    applicationsList: [],
  },
  getters: {
    getApplicationsList: state => state.applicationsList,
  },
  mutations: {
    SET_ALL_APPLICATIONS: (state, data) => {
      state.applicationsList = data
    },
  },
  actions: {
    fetchApplicationsList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/applications', {
            params: queryParams,
          })
          .then(response => {
            ctx.commit('SET_ALL_APPLICATIONS', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchApplicationById(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/applications/${id}`)
          .then(response => resolve(response.data.data))
          .catch(error => reject(error))
      })
    },
    postSessionsInApplication(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/applications/${id}/sessions`, queryParams)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    postApplicationPayCondition(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/application-pay-conditions`, queryParams)
          .then(response => {
            resolve(response.data.data)
          })
          .catch(error => reject(error))
      })
    },
  },
}