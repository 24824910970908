<template>
  <transition-group name="slide-fade" class="alert-messages" tag="div">
    <div 
      v-for="(message,index) in messages"
      class="alert"
      :key="message.application_trigger_communication_d"
    >
      <div class="alert__header">
        <p v-html="message.message"></p>
        <div 
          class="alert__header-close"
          @click="closeInfoMessage(index)"
        ></div>
      </div>
    </div> 
  </transition-group>
</template>

<script>
export default {
  name: 'AlertMessage',
  computed: {
    messages() {
      return this.$store.getters['alertMessage/getInfoMessages']
    },
  },
  methods: {
    closeInfoMessage(index) {
      this.$store.commit('alertMessage/REMOVE_INFO_MESSAGE', index)
    },
    removeAllInfoMessages() {
      this.$store.commit('alertMessage/SET_INFO_MESSAGES', [])
    },
  },
  created() {
    setTimeout(() => {
      this.removeAllInfoMessages()
    }, 1000 * 60 * 2)
  }
}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all .8s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(100px);
  opacity: 0;
}
</style>