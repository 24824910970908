<template>
    <div class="site">
        <site-loader ref="loader"/>
        <site-header/>

        <div class="site__content">
            <router-view/>
        </div>

        <site-footer/>
        
        <alert-message v-if="infoMessages.length" />
    </div>
</template>

<script>
    import SiteHeader from "../components/site/SiteHeader";
    import SiteFooter from "../components/site/SiteFooter";
    import SiteLoader from "../components/site/Loader";
    import AlertMessage from '@/components/site/AlertMessage.vue';
    import { mapGetters } from 'vuex'

    export default {
      name: "MainLayout",
      components: { SiteFooter, SiteHeader, SiteLoader, AlertMessage },
      computed: {
        ...mapGetters({
          isLoading: 'getLoadingStatus',
          infoMessages: 'alertMessage/getInfoMessages',
        }),
      },
      watch: {
        isLoading(val) {
          this.$refs.loader.loading = val
        },
      },
    }
</script>

<style scoped>

</style>
