<template>
    <div id="app">
        <component :is="layout">
            <router-view/>
        </component>
    </div>
</template>

<script>
  import { provideToast } from 'vue-toastification/composition'

  import MainLayout from '@/layouts/MainLayout.vue';

  export default {
    components: {
      'main-layout': MainLayout,
    },
    computed: {
      layout() {
        return `${this.$route.meta.layout || 'main'}-layout`
      }
    },
    async created () {
      document.title = this.$config['name']
      document.getElementById('favicon').setAttribute('href', this.$config['favicon'])
      await this.$store.dispatch('authSettings/fetchAuthSettings')
    },
    setup() {
      provideToast({
        hideProgressBar: true,
        closeOnClick: false,
        closeButton: false,
        icon: false,
        timeout: 3000,
        transition: 'Vue-Toastification__fade',
      })
    },
  }
</script>

<style lang="scss">
    @import "assets/scss/common.scss";
    #app {
        min-height: 100%;
    }
</style>
