<template>
  <nav class="menu" :class="{ 'is-opened': value }">
    <div class="menu-mobile-header desktop-hidden">
      <router-link
        :to="{ name: 'home' }"
        class="logo"
        @click.native="toggleMenu"
      >
        <img :src="$config['logo']" :alt="$config['name']" />
      </router-link>
      <span class="menu-close" @click="toggleMenu"></span>
    </div>
    <hr class="desktop-hidden" />

    <a
      v-if="$config['hide_main_page']"
      :href="$config['cgi_main_page']" 
      class="site__header-link"
      target="_blank"
      @click="toggleMenu"
    >
      Home
    </a>

    <router-link
      v-for="link in links"
      :key="link.name"
      :to="{ name: link.name }"
      active-class="is-current"
      :slot="'a'"
      :exact="link.exact"
      class="site__header-link"
      @click.native="toggleMenu"
    >
      {{ link.title }}
    </router-link>

    <!-- <a 
      v-if="$config['hide_main_page']"
      :href="$config['cgi_about_page']" 
      class="site__header-link"
      target="_blank"
      @click="toggleMenu"
    >
      About
    </a> -->

    <hr class="desktop-hidden" />

    <router-link
      v-if="!isUserLoggedIn"
      :to="{ name: 'auth-login' }"
      class="site__header-link desktop-hidden"
      @click.native="toggleMenu"
      >Login</router-link
    >
    <router-link
      v-if="!isUserLoggedIn"
      :to="{ name: 'registration' }"
      class="site__header-link desktop-hidden"
      @click.native="toggleMenu"
      >Registration</router-link
    >
    <a v-if="isUserLoggedIn" class="desktop-hidden" @click="logout"> Logout </a>
  </nav>
</template>

<script>
import { isUserLoggedIn } from "@/auth/utils";

export default {
  name: "Navigation",
  props: ["value"],
  computed: {
    links() {
      if (this.$config["hide_main_page"]) {
        return [{ title: "Register Now", name: "programs-list" }];
      }
      return [
        { title: "Home", name: "home", exact: true },
        { title: "Register Here", name: "programs-list" },
        { title: "About", name: "about", exact: true },
        // {title: "Information", name: 'information'},
      ];
    },
    isUserLoggedIn() {
      return this.$store.getters.getUserStatus || isUserLoggedIn();
    },
  },
  methods: {
    logout() {
      this.$emit("logout");
      this.$emit("toggleMenu");
    },
    toggleMenu() {
      this.$emit("toggleMenu");
    },
  },
};
</script>

<style scoped>
</style>
