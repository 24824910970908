import axios from '@axios'

export default {
  namespaced: true,
  state: {
    programsList: [],
    program: {},
  },
  getters: {
    getProgramsList: state => state.programsList,
    getProgram: state => state.program,
  },
  mutations: {
    SET_ALL_PROGRAMS: (state, data) => {
      state.programsList = data
    },
    SET_PROGRAM: (state, data) => {
      state.program = data
    },
  },
  actions: {
    fetchProgramList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/programs', {
            params: queryParams,
          })
          .then(response => {
            ctx.commit('SET_ALL_PROGRAMS', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchProgramById(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/programs/${id}`)
          .then(response => {
            ctx.commit('SET_PROGRAM', response.data.data)
            resolve(response.data.data)
          })
          .catch(error => reject(error))
      })
    },
  },
}
