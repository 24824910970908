import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios
  .create({
    baseURL: process.env.VUE_APP_ROOT_API,
    // timeout: 1000,
    // headers: { 'X-Custom-Header': 'foobar' },
  })

Vue.prototype.$http = axiosIns

export default axiosIns
